import clsx from 'clsx';
import { Link } from 'wouter';
import { ArrowIcon } from '../icons/arrow';

export const ArrowLink = (ps: { id: string; direction: 'prev' | 'next' }) => {
  return (
    <Link href={`/workstations/${ps.id}`}>
      <ArrowIcon
        className={clsx(
          'w-8 h-8 fill-transparent hover:scale-110 transition-transform',
          ps.direction === 'prev' && 'transform rotate-180',
        )}
      />
    </Link>
  );
};
