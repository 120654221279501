import { Api } from '../api';
import { UserPreview } from './user-preview';
import { HeadingName } from './heading-name';
import { useRoute } from 'wouter';
import clsx from 'clsx';

export const Header = () => {
  const line = Api.current_line();
  const [match] = useRoute('/orders');

  return (
    <div className="h-full flex items-center">
      <div
        className={clsx(
          'h-full px-8 my-4 w-1/4',
          match
            ? 'text-neutral-500 border-b-2 border-neutral-500 border-opacity-30'
            : 'bg-black text-white',
        )}>
        <div
          className={clsx(
            'h-full flex items-center justify-between border-neutral-500 border-opacity-30',
            match ? '' : 'border-b-2',
          )}>
          <span
            className="h-full w-44 flex items-center font-bold text-4xl bg-contain bg-no-repeat bg-center"
            style={{
              backgroundImage: match
                ? 'url(/leas-logo-gray.png)'
                : 'url(/leas-logo.png)',
            }}
          />
        </div>
      </div>
      <div
        className={clsx(
          'h-full w-3/4 px-8 flex items-center justify-between border-b-2 border-neutral-500 border-opacity-30',
          match && 'border-l-2',
        )}>
        <HeadingName
          name={line.name}
          status={line.status}
          started_at={line.started_at}
        />
        <UserPreview />
      </div>
    </div>
  );
};
