export const SearchIcon = (ps: React.SVGAttributes<SVGElement>) => (
  <svg
    width="18"
    height="18"
    viewBox="0 0 18 18"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...ps}>
    <path
      d="M16.6 17.2929L10.6536 11.3464L10.3371 11.03L9.98765 11.3096C9.53397 11.6725 9.00825 11.9632 8.40607 12.1794C7.81354 12.3921 7.17934 12.5 6.5 12.5C4.8128 12.5 3.40359 11.9215 2.24155 10.7594C1.0788 9.59669 0.5 8.18712 0.5 6.5C0.5 4.81288 1.0788 3.40331 2.24155 2.24055C3.40359 1.07852 4.8128 0.5 6.5 0.5C8.18711 0.5 9.59665 1.07846 10.7594 2.24046C11.9215 3.4032 12.5 4.8128 12.5 6.5C12.5 7.17934 12.3921 7.81354 12.1794 8.40607C11.9632 9.00825 11.6725 9.53397 11.3096 9.98765L11.03 10.3371L11.3464 10.6536L17.2929 16.6L16.6 17.2929ZM10.0414 10.0417L10.0417 10.0414C11.0111 9.07126 11.5 7.88103 11.5 6.5C11.5 5.11897 11.0111 3.92874 10.0417 2.95858L10.0414 2.95831C9.07126 1.9889 7.88103 1.5 6.5 1.5C5.11897 1.5 3.92874 1.9889 2.95858 2.95831L2.95831 2.95858C1.9889 3.92874 1.5 5.11897 1.5 6.5C1.5 7.88103 1.9889 9.07126 2.95831 10.0414L2.95858 10.0417C3.92874 11.0111 5.11897 11.5 6.5 11.5C7.88103 11.5 9.07126 11.0111 10.0414 10.0417Z"
      fill="#999999"
      stroke="#777777"
    />
  </svg>
);
