import clsx from 'clsx';
import { motion } from 'framer-motion';
import { useState } from 'react';
import { SearchIcon } from '../icons/search';
import { Parameter } from './parameter';

export const ExpandedSidebar = (ps: { open: boolean; secondary?: boolean }) => {
  const [search, setSearch] = useState('');
  return (
    <motion.div
      className={clsx(
        'absolute z-50 top-0 left-full h-full',
        ps.secondary ? 'bg-white/90' : 'bg-black/90',
      )}
      initial={{
        width: '0%',
        padding: 0,
      }}
      animate={{
        width: ps.open ? '200%' : '0%',
        padding: ps.open ? 8 : 0,
      }}>
      <div
        className={clsx(
          'w-1/2 h-[40px] px-4 flex items-center',
          !ps.open && 'hidden',
        )}>
        <div
          className={clsx(
            'w-full pr-2 text-neutral-500 rounded-md flex items-center gap-2 border border-neutral-500',
            ps.secondary ? 'bg-white' : 'bg-black',
          )}>
          <input
            className="text-xs bg-transparent p-2 flex-grow outline-none"
            placeholder="Cerca Parametro"
            value={search}
            onChange={(e) => setSearch(e.target.value)}
          />
          <SearchIcon className="w-4 h-4 fill-white" />
        </div>
      </div>
      <div
        className={clsx('flex flex-wrap items-start overflow-y-auto')}
        style={{ height: 'calc(100% - 40px)' }}>
        {Array.from({ length: 30 }).map((_, i) => (
          <div key={i} className="w-1/2 h-fit px-4 my-2">
            <Parameter
              label={`Parametro ${i + 7}`}
              value={`Val ${i + 7}`}
              unit="(udm)"
              size="l"
            />
            <hr className="opacity-20" />
          </div>
        ))}
      </div>
    </motion.div>
  );
};
