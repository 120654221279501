import { useParams } from 'wouter';
import { Api } from '../api';
import { HeadingName } from '../shared/heading-name';
import { useState } from 'react';
import { SidebarHeader } from '../shared/sidebar-header';
import { Parameter } from '../shared/parameter';
import { format_date, format_percentage } from '../shared/formatters';
import { ExpectedTime } from '../shared/expected-time';
import { Divider } from '../shared/divider';
import { ArrowLink } from '../shared/arrow-link';
import clsx from 'clsx';
import { ExpandedSidebar } from '../shared/expanded-sidebar';
import { Timer } from '../shared/timer';

export const Workstation = () => {
  const [expanded, setExpanded] = useState(false);
  const { workstation_id } = useParams<{ workstation_id: string }>();
  const w = Api.read_workstation(workstation_id);

  return (
    <div
      className="absolute z-10 h-full w-1/3 top-0 left-0 bg-white/90 border-r-2 border-neutral-500 border-opacity-30 p-6 flex flex-col gap-4"
      onClick={(e) => e.stopPropagation()}>
      <div className="flex items-center justify-between border-b-2 pb-4">
        <ArrowLink id={w.prev_workstation} direction="prev" />
        <HeadingName
          name={`Staz. ${w.id} - ${w.name}`}
          status={w.status}
          started_at={w.started_at}
          size="small"
        />
        <ArrowLink id={w.next_workstation} direction="next" />
      </div>
      <SidebarHeader
        title="Parametri Stazione"
        expanded={expanded}
        onExpand={() => setExpanded((prev) => !prev)}
      />
      <div className="relative w-full flex-grow flex flex-col justify-around">
        <Parameter label="Ordine corrente" value={w.current_order} />
        <Divider />
        <Parameter label="Progr. saldatura" value={w.program} />
        <Divider />
        <Parameter label="Codice articolo" value={w.item} />
        <Divider />
        <Parameter label="Descr. articolo" value={w.description} />
        <Divider />
        <Parameter
          label="Tempo atteso completamento"
          value={<ExpectedTime end_by={w.etc} />}
          unit="(hh:mm:ss)"
          size="l"
        />
        <Divider />
        <Parameter
          label="Buoni / Previsti"
          value={
            <span className="w-full flex gap-2 justify-end items-end">
              <span className="text-green-500 text-4xl font-bold">
                {w.passed}
              </span>
              <span className="text-neutral-500 font-thin text-4xl">/</span>
              <span className="text-black text-4xl font-bold">
                {w.quantity}
              </span>
            </span>
          }
          unit={
            <span>
              <b>{format_percentage(w.passed / w.quantity)}</b> completamento
            </span>
          }
          size="l"
        />
        <Divider />
        <Parameter
          label="Scarti"
          value={<span className="text-red-500">{w.wasted}</span>}
          size="l"
        />
        <Divider />
        <Parameter
          label="OEE Stazione"
          value={
            <span className="text-green-500">{format_percentage(w.oee)}</span>
          }
          size="l"
        />
        <Divider />
        <Parameter
          label="Consumo Stazione"
          value={w.energy_consumption}
          unit="(kWh)"
          size="l"
        />
        <div>
          <div className="mb-4 flex items-center gap-2 text-neutral-500">
            <span
              className={clsx(
                w.warnings.length > 0 && 'text-yellow-500 font-bold',
              )}>
              {w.warnings.length} Warning
            </span>
            <span className="text-neutral-400">/</span>
            <span
              className={clsx(w.errors.length > 0 && 'text-red-500 font-bold')}>
              {w.errors.length} Errori
            </span>
          </div>
          <div className="flex flex-col gap-2 pr-2 max-h-36 overflow-auto">
            {w.errors.map((error, i) => (
              <IssuePreview
                key={i}
                colors="border-red-500 bg-red-100 text-red-600"
                issue={error}
              />
            ))}
            {w.warnings.map((error, i) => (
              <IssuePreview
                key={i}
                colors="border-yellow-500 bg-yellow-100 text-yellow-600"
                issue={error}
              />
            ))}
          </div>
        </div>
      </div>
      <ExpandedSidebar open={expanded} secondary />
    </div>
  );
};

const IssuePreview = (ps: {
  colors: string;
  issue: { code: string; date: number; message: string };
}) => (
  <div
    className={clsx(
      'rounded-xl py-2 px-4 border-2 flex items-center justify-between',
      ps.colors,
    )}>
    <div className="flex flex-col justify-between">
      <small className="text-xs font-light">{format_date(ps.issue.date)}</small>
      <span className="text-sm font-bold">{ps.issue.message}</span>
    </div>
    <span className="font-bold">
      <Timer start={ps.issue.date} />
    </span>
  </div>
);
