export const FilterIcon = (ps: React.SVGAttributes<SVGElement>) => (
  <svg width="24" height="23" viewBox="0 0 24 23" {...ps}>
    <path
      d="M11.5 15.4301L1.54071 3.87255L21.4593 3.87255L11.5 15.4301Z"
      fill="#777777"
    />
    <path
      d="M10.124 12.8998H12.884V22.7898L10.124 20.4898V12.8998Z"
      fill="#777777"
    />
    <circle cx="19" cy="6" r="4.5" fill="#DFDFDF" stroke="#777777" />
    <g clipPath="url(#clip0_1_375)">
      <path
        d="M21.3334 4.13663L20.8634 3.66663L19.0001 5.52996L17.1367 3.66663L16.6667 4.13663L18.5301 5.99996L16.6667 7.86329L17.1367 8.33329L19.0001 6.46996L20.8634 8.33329L21.3334 7.86329L19.4701 5.99996L21.3334 4.13663Z"
        fill="#777777"
      />
    </g>
    <defs>
      <clipPath id="clip0_1_375">
        <rect width="8" height="8" fill="white" transform="translate(15 2)" />
      </clipPath>
    </defs>
  </svg>
);
