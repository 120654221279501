import clsx from 'clsx';
import { Api } from '../api';
import { Link, Route, Switch, useLocation, useRoute } from 'wouter';
import { Workstation } from './workstation';
import { bg_color, border_color } from '../shared/colors';

export const LinePreview = () => {
  const line = Api.current_line();
  const [, setLocation] = useLocation();
  const [, rest] = useRoute<{ workstation_id: string }>(
    '/workstations/:workstation_id',
  );

  return (
    <div
      className="relative w-full p-6 bg-no-repeat bg-center bg-contain"
      onClick={() => setLocation('/')}
      style={{
        backgroundImage: `url(/line.png)`,
      }}>
      {line.workstations.map((ws) => {
        const active = rest?.workstation_id === ws.id;
        return (
          <Link
            key={ws.id}
            href={`/workstations/${ws.id}`}
            onClick={(e) => e.stopPropagation()}>
            <div
              className={clsx(
                'group w-[34px] h-[34px] rounded-full absolute cursor-pointer transition-transform text-center border-4 border-white',
                active ? 'scale-110' : 'hover:scale-110',
                bg_color(ws.status),
              )}
              style={{ left: `${ws.position[0]}%`, top: `${ws.position[1]}%` }}>
              <span
                className={clsx(
                  active ? 'inline' : 'hidden group-hover:inline',
                  'absolute -top-[66px] -left-[68px] w-[160px] h-[160px] bg-opacity-10 border-4 rounded-full',
                  bg_color(ws.status),
                  border_color(ws.status),
                )}
              />
            </div>
          </Link>
        );
      })}
      <Switch>
        <Route path="/workstations/:workstation_id" component={Workstation} />
      </Switch>
    </div>
  );
};
