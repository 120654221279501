export const ArrowIcon = (ps: React.SVGAttributes<SVGElement>) => (
  <svg
    width="36"
    height="36"
    viewBox="0 0 36 36"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...ps}>
    <g clipPath="url(#clip0_1_500)">
      <path
        d="M12.8848 24.885L19.7548 18L12.8848 11.115L14.9998 9L23.9998 18L14.9998 27L12.8848 24.885Z"
        fill="#999999"
      />
    </g>
    <defs>
      <clipPath id="clip0_1_500">
        <rect width="36" height="36" fill="white" />
      </clipPath>
    </defs>
  </svg>
);
