import clsx from 'clsx';
import React from 'react';

export const Parameter = (ps: {
  label: string;
  value: React.ReactNode;
  unit?: React.ReactNode;
  size?: 's' | 'm' | 'l';
}) => (
  <div className="w-full flex items-center gap-2 py-2">
    <span className="flex flex-col text-sm text-neutral-500 w-1/3">
      <span>{ps.label}</span>
      <small className="text-neutral-700">{ps.unit}</small>
    </span>
    <span
      className={clsx(
        'text-right w-2/3 font-bold truncate overflow-hidden',
        ps.size === 'm' && 'text-xl',
        ps.size === 'l' && 'text-4xl',
        ps.size === 's' && 'text-xs',
        !ps.size && 'text-xl',
      )}>
      {ps.value}
    </span>
  </div>
);
