export const Api = {
  current_version: '1.01',
  current_line: () => ({
    id: '',
    name: 'Soldering line x400-fz',
    status: 'running',
    started_at: Date.now() - 1000 * 60 * 9,
    workstations
  }),
  current_user: () => ({
    id: '1',
    name: 'Massimiliano D.',
    started_at: Date.now() - 1000 * 60 * 9,
    role: 'supervisor',
    is_admin: true,
  }),
  current_order: () => current_order,
  read_workstation: (id: string) => ({
    id,
    name: 'Saldatura',
    status: workstations.find((ws) => ws.id === id)?.status!,
    current_order: 'WO334-FF449A',
    item: 'A12667089',
    description: 'Caldaia ⌀70cm Mod. Silver Special lorem ipsum dolor sit ame',
    program: 'R16536-V40',
    next_order: 'WO335-FF570B',
    started_at: Date.now() - 1000 * 60 * 9,
    ended_at: undefined,
    etc: 1000 * 60 * 12,
    oee: 0.61,
    energy_consumption: 7.4,
    warning: true,
    error: false,
    quantity: 150,
    wasted: 12,
    passed: 130,
    warnings: [
      {
        code: 'W-001',
        date: Date.now() - 1000 * 60 * 5,
        message: 'Attenzione: bassa temperatura',
      },
      {
        code: 'W-002',
        date: Date.now() - 1000 * 60 * 3,
        message: 'Attenzione: bassa pressione',
      },
    ],
    errors: [
      {
        code: 'E-001',
        date: Date.now() - 1000 * 60 * 2,
        message: 'Errore di saldatura',
      }
    ],
    prev_workstation: 'w-' + Math.max(0, Number(id.split('-')[1]) - 1),
    next_workstation: 'w-' + Math.min(workstations.length - 1, Number(id.split('-')[1]) + 1),
  }),
  list_orders: () => [
    current_order, current_order,
    current_order, current_order,
    current_order, current_order,
    current_order, current_order,
    current_order, current_order,
    current_order, current_order,
  ].map((order, i) => ({
    ...order,
    id: `WO${i + 1}-FF${i + 1}`,
    status: i % 2 === 0 ? 'open' : i % 3 === 0 ? 'error' : 'closed'
  })),
}


export type WorkStatus = 'running' | 'error' | 'warning' | 'standby' | string;

// MOCK

const workstations = [
  { id: 'w-1', position: [11, 45], status: 'running' },
  { id: 'w-2', position: [21, 38], status: 'running' },
  { id: 'w-3', position: [43, 34], status: 'error' },
  { id: 'w-4', position: [65, 25], status: 'warning' },
  { id: 'w-5', position: [75, 28], status: 'standby' },
  { id: 'w-6', position: [87, 20], status: 'running' },
]

const current_order = {
  id: 'WO334-FF449A',
  status: 'open', // open, standby, error, closed, scheduled
  item: 'A12667089',
  description: 'Caldaia ⌀70cm Mod. Silver Special lorem ipsum dolor sit ame',
  program: 'R145556-V22',
  quantity: 150,
  wasted: 12,
  passed: 130,
  warnings: 3,
  errors: 1,
  started_at: Date.now() - 1000 * 60 * 9,
  ended_at: undefined,
  etc: 1000 * 60 * 60 * 2, // tempo stimato a finire?
  oee: 0.83,
  energy_consumption: 12.4,
  next_order: 'WO335-FF570B'
}

export type Order = typeof current_order;