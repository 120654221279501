export const SidebarHeader = (ps: {
  title: string;
  expanded: boolean;
  onExpand(): void;
}) => {
  return (
    <h2 className="text-orange-500 text-xl font-bold flex items-center justify-between">
      {ps.title}
      <span className="cursor-pointer text-xs" onClick={ps.onExpand}>
        Riduci Parametri {ps.expanded ? '<<' : '>>'}
      </span>
    </h2>
  );
};
