import clsx from 'clsx';
import { useState } from 'react';
import { Api } from '../api';
import { Link } from 'wouter';
import { OrdersIcon } from '../icons/orders';
import { IronIcon } from '../icons/iron';
import { HistoryIcon } from '../icons/history';
import { Parameter } from '../shared/parameter';
import { WarningIcon } from '../icons/warning';
import { format_percentage } from '../shared/formatters';
import { SidebarHeader } from '../shared/sidebar-header';
import { ExpectedTime } from '../shared/expected-time';
import { ExpandedSidebar } from '../shared/expanded-sidebar';

export const Sidebar = () => {
  const [expanded, setExpanded] = useState(false);
  const order = Api.current_order();

  return (
    <div className="relative w-1/4 py-4 px-8 bg-black text-white flex flex-col justify-around">
      <SidebarHeader
        title="Parametri Linea"
        expanded={expanded}
        onExpand={() => setExpanded((prev) => !prev)}
      />
      <Parameter label="Ordine corrente" value={order.id} />
      <hr className="opacity-20" />
      <Parameter label="Progr. saldatura" value={order.program} />
      <hr className="opacity-20" />
      <Parameter label="Codice articolo" value={order.item} />
      <hr className="opacity-20" />
      <Parameter label="Descr. articolo" value={order.description} />
      <hr className="opacity-20" />
      <Parameter
        label="Tempo atteso completamento"
        value={<ExpectedTime end_by={order.etc} />}
        unit="(hh:mm:ss)"
        size="l"
      />
      <hr className="opacity-20" />
      <Parameter
        label="Buoni / Previsti"
        value={
          <span className="w-full flex gap-2 justify-end items-end">
            <span className="text-green-500 text-4xl font-bold">
              {order.passed}
            </span>
            <span className="text-neutral-500 font-thin text-4xl">/</span>
            <span className="text-white text-4xl font-bold">
              {order.quantity}
            </span>
          </span>
        }
        unit={
          <span>
            <b>{format_percentage(order.passed / order.quantity)}</b>{' '}
            completamento
          </span>
        }
        size="l"
      />
      <hr className="opacity-20" />
      <Parameter
        label="Scarti"
        value={<span className="text-red-500">{order.wasted}</span>}
        size="l"
      />
      <hr className="opacity-20" />
      <Parameter
        label="OEE Linea"
        value={
          <span className="text-green-500">{format_percentage(order.oee)}</span>
        }
        size="l"
      />
      <hr className="opacity-20" />
      <Parameter
        label="Consumo Linea"
        value={order.energy_consumption}
        unit="(kWh)"
        size="l"
      />
      <hr className="opacity-20" />
      <Parameter label="Parametro 6" value="Val 6" unit="(udm)" size="l" />

      <Link to="/issues">
        <div className="flex items-center justify-between p-4 rounded-xl text-white bg-[#EC4407] shadow-md shadow-neutral-800 hover:shadow-none hover:scale-105 transition-transform cursor-pointer">
          <div className="flex items-center gap-4">
            <WarningIcon className="w-8 h-8 fill-white" />
            <div className="flex flex-col">
              <span className="">Gestione</span>
              <span className="">Warning / Errori</span>
            </div>
          </div>
          <div className="flex gap-2 items-center text-5xl justify-self-end">
            <span className="font-bold">{order.warnings}</span>
            <span className="font-thin">/</span>
            <span className="font-bold">{order.errors}</span>
          </div>
        </div>
      </Link>

      <div className="w-full flex items-center gap-2 mt-2">
        <ButtonLink
          className="w-1/3 bg-blue-500"
          label="Gestione Ordini"
          icon={<OrdersIcon className="w-8 h-8 fill-white" />}
          to="/orders"
        />
        <ButtonLink
          className="w-1/3 bg-orange-500"
          icon={<IronIcon className="w-8 h-8 fill-white" />}
          label="Controllo Saldatura"
          to="/"
        />
        <ButtonLink
          className="w-1/3 bg-neutral-700"
          icon={<HistoryIcon className="w-8 h-8 fill-white" />}
          label="Storico Dati"
          to="/historic-data"
        />
      </div>
      <ExpandedSidebar open={expanded} />
    </div>
  );
};

const ButtonLink = (ps: {
  label: string;
  icon: React.ReactNode;
  to: string;
  className?: string;
}) => (
  <Link
    className={clsx(
      'text-white p-3 rounded-xl text-left text-sm flex flex-col font-semibold leading-tight shadow-md shadow-neutral-800',
      'hover:shadow-none hover:scale-105 transition-transform',
      ps.className,
    )}
    href={ps.to || '/'}>
    <span className="mb-4">{ps.icon}</span>
    {ps.label.split(' ').map((word, i) => (
      <span key={i}>{word}</span>
    ))}
  </Link>
);
