// format DD mmm YY • hh:mm:ss with padding to the left
export const format_date = (date: number) => {
  const d = new Date(date);
  const pad = (n: number) => n.toString().padStart(2, '0');
  return `${pad(d.getDate())} ${d.toLocaleString('default', {
    month: 'short',
  })} ${d.getFullYear()} • ${pad(d.getHours())}:${pad(d.getMinutes())}:${pad(
    d.getSeconds(),
  )}`;
};

export const format_duration = (duration: number) => {
  const pad = (n: number) => n.toString().padStart(2, '0');
  const hours = Math.floor(duration / 1000 / 60 / 60);
  const minutes = Math.floor((duration / 1000 / 60) % 60);
  const seconds = Math.floor((duration / 1000) % 60);
  return `${pad(hours)}:${pad(minutes)}:${pad(seconds)}`;
};

export const format_percentage = (value: number) =>
  `${Math.round(value * 100)}%`;
