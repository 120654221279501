export const WarningIcon = (ps: React.SVGAttributes<SVGElement>) => (
  <svg
    width="36"
    height="36"
    viewBox="0 0 36 36"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...ps}>
    <path
      d="M12.9 33.75L10.05 28.95L4.65 27.75L5.175 22.2L1.5 18L5.175 13.8L4.65 8.25L10.05 7.05L12.9 2.25L18 4.425L23.1 2.25L25.95 7.05L31.35 8.25L30.825 13.8L34.5 18L30.825 22.2L31.35 27.75L25.95 28.95L23.1 33.75L18 31.575L12.9 33.75ZM14.175 29.925L18 28.275L21.9 29.925L24 26.325L28.125 25.35L27.75 21.15L30.525 18L27.75 14.775L28.125 10.575L24 9.675L21.825 6.075L18 7.725L14.1 6.075L12 9.675L7.875 10.575L8.25 14.775L5.475 18L8.25 21.15L7.875 25.425L12 26.325L14.175 29.925ZM18 25.5C18.425 25.5 18.7812 25.3563 19.0688 25.0688C19.3563 24.7812 19.5 24.425 19.5 24C19.5 23.575 19.3563 23.2188 19.0688 22.9313C18.7812 22.6438 18.425 22.5 18 22.5C17.575 22.5 17.2188 22.6438 16.9313 22.9313C16.6438 23.2188 16.5 23.575 16.5 24C16.5 24.425 16.6438 24.7812 16.9313 25.0688C17.2188 25.3563 17.575 25.5 18 25.5ZM16.5 19.5H19.5V10.5H16.5V19.5Z"
      fill="white"
    />
  </svg>
);
