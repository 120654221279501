import { useState, useEffect } from 'react';

export const ExpectedTime = (ps: { end_by: number }) => {
  const [time, setTime] = useState<number>(ps.end_by);
  useEffect(() => {
    const interval = setInterval(() => {
      setTime((prev) => prev - 1000);
    }, 1000);
    return () => clearInterval(interval);
  }, []);
  return <span>{new Date(time).toISOString().substr(11, 8)}</span>;
};
